var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c("div", { staticClass: "container" }, [
        _c("h5", { staticClass: "col-12 mt-3" }, [_vm._v("Alle teams")]),
        _c("div", { staticClass: "col-12 mt-4 filters-container" }, [
          _c(
            "div",
            { staticClass: "col-lg-8" },
            [
              _c(
                "b-form-group",
                { staticClass: "m-0" },
                [
                  _c("b-form-checkbox-group", {
                    attrs: { options: _vm.labelOptions },
                    model: {
                      value: _vm.selectedLabelIds,
                      callback: function ($$v) {
                        _vm.selectedLabelIds = $$v
                      },
                      expression: "selectedLabelIds",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-lg-4" },
            [
              _c("b-form-select", {
                attrs: { options: _vm.clubSelectOptions },
                model: {
                  value: _vm.clubSelected,
                  callback: function ($$v) {
                    _vm.clubSelected = $$v
                  },
                  expression: "clubSelected",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "col-12 mt-4" }, [
          _c("table", { staticClass: "table table-hover" }, [
            _c("thead", [
              _c("tr", [
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("teams.allTeams.abbreviation"))),
                ]),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("teams.allTeams.name"))),
                ]),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("teams.allTeams.level"))),
                ]),
                _c(
                  "th",
                  { staticClass: "text-center", attrs: { scope: "col" } },
                  [_vm._v(_vm._s(_vm.$t("teams.allTeams.isFavourite")))]
                ),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.teams, function (team) {
                return _c("tr", { key: team.id }, [
                  _c("td", [_vm._v(_vm._s(team.shortName))]),
                  _c("td", [_vm._v(_vm._s(team.name))]),
                  _c("td", [_vm._v(_vm._s(team.level))]),
                  _c("td", { staticClass: "text-center" }, [
                    team.isFavourite
                      ? _c("i", {
                          staticClass:
                            "fas fa-star favourite-icon is-favourite",
                          on: {
                            click: function ($event) {
                              return _vm.changeStatus(team.id, false)
                            },
                          },
                        })
                      : _c("i", {
                          staticClass: "far fa-star favourite-icon",
                          on: {
                            click: function ($event) {
                              return _vm.changeStatus(team.id, true)
                            },
                          },
                        }),
                  ]),
                ])
              }),
              0
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }