













































import { Action, Getter } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { MyTeamListItem } from '../models/my_teams.model';
import { TeamLabels } from '../models/team-labels.model';
import { ClubSelectItem } from '../models/club-select-item.model';
import { Club } from '../../login/models/club.model';
import { ToastHelper } from '@/shared/helpers/toast.helper';

@Component
export default class AllTeams extends Vue {
  loaded = false;

  selectedClubId = '';
  selectedLabelIds: number[] = [];

  labelOptions: { value: number, text: string}[] = [];
  clubSelectOptions: { value: string, text: string}[] = [];


  @Action
  private setLoading!: (loading: boolean) => Promise<void>;
  @Action
  private loadAllMyTeamsLabels! : () => Promise<void>;
  @Action
  private loadAllMyTeams! : (payload: { clubGuid: string, teamLabels: number[], onlyFavourites: boolean }) => Promise<void>;
  @Action
  private loadClubsSelectList! : () => Promise<void>
  @Action 
  private changeFavouriteStatus! : (payload: { teamId: string, status: boolean }) => Promise<void>

  @Getter
  currentClub!: Club;
  @Getter
  teamLabels!: TeamLabels[];
  @Getter
  teams!: MyTeamListItem[];
  @Getter
  clubSelectList!: ClubSelectItem[];


  async created(): Promise<void> {
    try{
      await this.setLoading(true);

      await this.loadAllMyTeamsLabels();
      await this.loadClubsSelectList();      
      
      this.teamLabels.forEach(x => {
        this.labelOptions.push({ value: x.id, text: x.name });
        this.selectedLabelIds.push(x.id);
      });

      this.clubSelectList.forEach(x => this.clubSelectOptions.push({value: x.id, text: x.name}));

      this.clubSelected = this.currentClub.id;

      this.applyWatchers();

      await this.loadAllMyTeams({
        clubGuid: this.selectedClubId,
        teamLabels: this.selectedLabelIds,
        onlyFavourites: false
      });

    }
    catch(ex) {
      ToastHelper.showError(this.$bvToast, 'Fout opgetreden. Probeer het nog eens.'); 
    }
    finally {
      await this.setLoading(false);
      this.loaded = true;
    }
  }

  async reloadTeams() : Promise<void> {
    try {
      await this.setLoading(true);
      this.loaded = false;

      await this.loadAllMyTeams({
        clubGuid: this.selectedClubId,
        teamLabels: this.selectedLabelIds,
        onlyFavourites: false
      });
    }
    catch (ex) {
      ToastHelper.showError(this.$bvToast, 'Fout opgetreden. Probeer het nog eens.'); 
    }
    finally {      
      await this.setLoading(false);
      this.loaded = true;
    }
  }

  async changeStatus(teamId: string, status: boolean) : Promise<void> {
    try {
      await this.setLoading(true);
      this.loaded = false;

      await this.changeFavouriteStatus({ 
        teamId: teamId, 
        status: status });

    }
    catch (ex) {
      ToastHelper.showError(this.$bvToast, 'Fout opgetreden. Probeer het nog eens.');
    }
    finally {   
      await this.setLoading(false);
      this.loaded = true;      
    }
  }

  applyWatchers() : void {
    this.$watch('selectedClubId', function () {
      this.reloadTeams();
    });

    this.$watch('selectedLabelIds', function () {
      this.reloadTeams();
    })
  }

  get clubSelected() : string {
    return this.selectedClubId;
  }

  set clubSelected(value: string) {
    this.selectedClubId = value;
  }
}
